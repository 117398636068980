<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Article
        <div class="card-header-actions">
          <a class="card-header-action" href="article" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
              <div class="sk-three-bounce">
                  <div class="sk-child sk-bounce1"></div>
                  <div class="sk-child sk-bounce2"></div>
                  <div class="sk-child sk-bounce3"></div>
              </div>
          </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate>
              <b-form-group id="titleInputGroup1"
                            label="Title"
                            label-for="title">
                <b-form-input id="title"
                              type="text"
                              v-model.lazy.trim="$v.form.title.$model"
                              :state="chkState('title')"
                              aria-describedby="titleFeedback"
                              placeholder="Please Enter Article Title"
                              autofocus />
                <b-form-invalid-feedback id="titleFeedback" v-for="(error , index) in errors.form.title" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="contentInputGroup2"
                            label="Content"
                            label-for="content">
                <quill-editor id="content"
                              v-model.lazy.trim="$v.form.content.$model"
                              :state="chkState('content')"
                              autocomplete='family-name'>
                </quill-editor>
                <div  v-if="this.form.content == '' " >
                  <p id="contentFeedback" v-for="(error , index) in errors.form.content" :key="index">
                    - {{ error }} <br>
                  </p>
                </div>
              </b-form-group>
              <div v-if="this.form.image != null">
                <b-img :src="baseUrlPath + this.form.image" fluid alt="Responsive image"></b-img>
              </div>
              <br>
              <b-form-group id="userNameInputGroup3"
                            label="Image"
                            label-for="userName">
                <b-form-file id="selectedImage"
                              :plain="true"
                              accept="image/*"
                              aria-describedby="imageFeedback"
                              v-model.lazy.trim="$v.form.selectedImage.$model"
                              :state="chkState('selectedImage')"
                              @change="onFileSelected">
                </b-form-file>
                <b-form-invalid-feedback id="imageFeedback" v-for="(error , index) in errors.form.image" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="typeInputGroup1"
                            label="Category"
                            label-for="category">
                <b-form-select id="category"
                              :plain="true"
                              v-model.lazy.trim="$v.form.category.$model"
                              :state="chkState('category')"
                              :options="[{value: '', text:'Please select'},
                                                {value: 'News', text: 'News'},
                                                {value: 'Promo', text: 'Promo'},
                                                {value: 'Maintenance', text: 'Maintenance'}]"
                              aria-describedby="categoryFeedback"
                              autofocus />
                <b-form-invalid-feedback id="categoryFeedback" v-for="(error , index) in errors.form.category" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength } from "vuelidate/lib/validators"

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        title: '',
        content: '',
        category: '',
        selectedImage: '',
      },
      baseUrlPath: process.env.VUE_APP_SECRET,
      errors: {
        message: [],
        code: '',
        status: [],
        headers: [],
        form: {
          title: [],
          content: [],
          image: [],
          category: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(2)
      },
      category: {
        required
      },
      content: {
        required
      },
      selectedImage: {
        required
      }
    }
  },
  created(){

    this.$http.put(`article-data/` + this.$route.params.id)
    .then((result) => {
      this.form.id = result.data.id;
      this.form.title = result.data.title;
      this.form.category = result.data.category;
      this.form.content = result.data.content;
      this.form.image = result.data.image;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
  methods: {
    onFileSelected(event) {
      this.form.selectedImage = event.target.files[0];
    },
    onSubmit() {
      this.validate()
      this.isLoading = true
      if (this.form.content == '') {
        var element = document.getElementById("content");
        element.classList.add("notValidate");
      }
          const formData =  new FormData();
          if (this.form.selectedImage == '') {
            formData.append('image', this.form.image)
            formData.append('title', this.form.title)
            formData.append('content', this.form.content)
            formData.append('category', this.form.category)
          }else{
            formData.append('image', this.form.selectedImage, this.form.selectedImage.name)
            formData.append('title', this.form.title)
            formData.append('content', this.form.content)
            formData.append('category', this.form.category)
          }


          this.$http.post(`article-update/` + this.$route.params.id, formData)
          .then(() => {
            this.isLoading = false
            this.$router.push("/article");
            this.$toasted.success('Article successfully updated!')
          }).catch((error) => {
            if (error.response) {
              this.isLoading = false
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if(this.errors.status == 422) {
                this.$toasted.error('Please fill out the form that must be required')
                this.errors.message = error.response.data.meta.message;
                this.errors.form.title = this.errors.message.title;
                this.errors.form.content = this.errors.message.content;
                this.errors.form.image = this.errors.message.image;
                this.errors.form.category = this.errors.message.category;
                this.errors.form.type = this.errors.message.type;
              }
            }
          })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  }
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
.preview {
  background-color: white;
  border: 1px solid #DDD;
  padding: 5px;
  width: 50%;
  height: 50%;
}
.image-preview {
  padding: 20px;
}

.invalid-feedback {
    font-size: 100% !important;
}
.notValidate {
  border: #f86c6b 1px solid;
}
#contentFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
